<template>
    <q-dialog 
      ref="dialogCannaConfirm" 
      content-class="cannaFormModal"
      transition-show="scale" 
      transition-hide="scale"
      :persistent="(is_Mobile || $q.screen.lt.sm)?true:false"
      :full-width="(is_Mobile || $q.screen.lt.sm)?true:false"
      :full-height="(is_Mobile|| $q.screen.lt.sm)?true:false"
      :no-esc-dismiss="(is_Mobile|| $q.screen.lt.sm)?false: ((modalForm.processing) ? true : false)"
      :no-backdrop-dismiss="(is_Mobile|| $q.screen.lt.sm)?false:true"
      @show="setShownDialog"
      @escape-key="doCloseDialog(true)"
    >
      <q-card class="planFormx ">
        <q-card-section horizontal class="q-card q-pa-md flex justify-between column noselect">
          <div class="closeButton">
            <cxIcon
              iconType="ui_close"
              size="24px"
              class=""
              :cls="'icon_close_svg'"
              :state="(modalForm.processing === false) ? 0 : 2"
              :hasMouseOver="true"
              :hasAction="true"
              :allowDisabled="true"
              :allowClick="true"
              @iconClick="doCloseDialog"
            />
          </div>
          <div class="form_title">
            {{modalTitle}}
          </div>
        </q-card-section>
        <q-separator />
        <q-card-section  style="max-height: 85vh" class="planFormxContents scroll">
          <!-- Contenido del formulario --> 
          <div>
            <div class="canna_forma_zona" style="">
              <div class="modalContents">
                <slot name="contentArea" v-bind="{process: modalForm.processing}"></slot>
              </div>

              <div class="buttonZone">
                <slot name="buttonsArea" v-bind="{process: modalForm.processing}"></slot>
              </div>
            </div>
          </div>

        </q-card-section>

        <q-inner-loading :showing="modalForm.processing">
          <q-spinner-orbit size="100px" color="indigo" />
        </q-inner-loading>

      </q-card>
    </q-dialog>
</template>
<script>
import helperBase_Mixin from '../../mixins/helperBase.js'
import logOut_Mixin from '../../mixins/requestMix.js'
import myplatform_Mixin from '../../mixins/platformMix.js'

export default {
  name: 'modalBaseEstructure_form',
  props: {
    modalTitle:{
      type: String,
      required:true,
      default: 'Titulo'
    },
  },
  components: {
    cxIcon: require('components/appIcons.vue').default
  },
  mixins: [
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin
  ],
  data () {
    return {
      version: '1.0.1',
      // -----------------
      initFormFlag: false,
      modalForm: {
        show: false,
        processing: false
      }
    }
  },
  computed: {
    parentX () {
      return this.$parent
    }
  },
  watch: {
    initFormFlag: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$forceUpdate()
      }
    },
    'modalForm.show': function (newVal, oldVal) {
      if (newVal !== oldVal && newVal === true) {
        this.$refs.dialogCannaConfirm.show()
      } else if (newVal !== oldVal && newVal === false) {
        this.$refs.dialogCannaConfirm.hide()
      }
      this.$forceUpdate()
    },
    'modalForm.processing': function (newVal, oldVal) {
      this.$forceUpdate()
    }
  },
  methods: {
    doShow () {
      this.modalForm.show = true
    },
    doHide () {
      this.modalForm.show = false
    },
    setShownDialog () {
      this.$cannaDebug('-- modalConfirm -- set Shown Dialog')
      this.initFormFlag = true
      setTimeout(() => {
        this.$forceUpdate()
      }, 200)
    },
    doCloseDialog (checkProcessing) {
      if (checkProcessing !== undefined) {
        if (this.modalForm.processing === true) {
          return
        }
      }
      this.initFormFlag = false
      this.$emit('doCloseDialog', { newState: false })
      this.modalForm.show = false
    }
  },
  beforeCreate () {
    this.$cannaDebug('-- modalConfirm -- beforeCreate')
    this.initFormFlag = false
  },
  created () {
    // this.$cannaDebug('-- modalConfirm -- created')
  },
  beforeMount () {
    // this.$cannaDebug('-- modalConfirm --  beforeMount')
  },
  mounted () {
    this.$cannaDebug('-- modalConfirm -- mounted')
  },
  beforeUpdate () {
    // this.$cannaDebug('-- modalConfirm -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- modalConfirm -- updated')
  },
  activated () {
    // this.$cannaDebug('-- modalConfirm -- activated')
  },
  deactivated () {
    // this.$cannaDebug('-- modalConfirm -- deactivated')
  },
  beforeDestroy () {
    // this.$cannaDebug('-- modalConfirm -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- modalConfirm -- destroyed')
  }
}
</script>
