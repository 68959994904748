
<template>

  <div class="dash_vacs_mainpage canna_pageFlexZone" style="width: 100%;height: calc(100% - 0px);overflow: hidden;">

    <template v-if="hasVacanteData===true && zinitData===true">
      <pageHeader
        :showSubtitle="true"
        :showTitleAltZone="true"
        :showTlbZone="false"
        :cls_title_altZone="'pruebaAltZonewwww'"
        class="canna_vacantesPageHeader canna_vacantesDetailPageHeader"
      >
        <slot>
          <div style="width: calc(100% - 10px);position: relative;">

            <div class="titleGoBack action_item" style="display: flex;align-items: center;"
              @click.stop="goVacantesPage"
            >
              <div>
                <cxIcon 
                  iconType="ui_goback"
                  class="xxx-demo zzz-prueba"
                  :cls="'icon_close_svg'"
                  size="24px"
                  :state="1"
                  :hasMouseOver="true"
                  :hasAction="true"
                  :allowDisabled="false"
                  :allowClick="true"
                  @iconClick="goVacantesPage"
                />
              </div>
              <div style="margin-left:8px">Vacantes</div>
            </div>

            <div style="margin-top: 8px;">
              {{currentVacante.nombre_vacante}}
            </div>
          </div>
        </slot>
        <template v-slot:subtitle>
          <div v-html="currentVacante.z_subtitulo"></div>
        </template>

        <template v-slot:altZone>

          <template v-if="(currentVacante.postulado!==1)">
            <template v-if="($q.screen.lt.md)">
              <q-btn color="app_mainBtn" label="Postularme" icon="add" no-caps unelevated 
                style="margin-top: 8px;"
                @click.stop="addJobProfilePersona($event)"
              />
            </template>
            <template v-else>
              <q-btn color="app_mainBtn" label="Postularme"  icon="add" no-caps unelevated 
                @click.stop="addJobProfilePersona($event)"
              />
            </template>
            </template>
          <template v-else>
            <!-- POSTULADO -->
          
            <template v-if="(is_Mobile ==false || $q.screen.gt.sm)">
              <div style="margin-right: 24px;">
                <div style="background-color: #6FCF97;border-radius: 32px;display: flex;color: #fff;padding: 12px 16px; align-items: center;">
                  <div>
                    <q-icon name="check_circle" style="color: #fff; font-size: 1.4em;" />
                  </div>
                  <div style="font-size: 14px;font-weight: 400;margin-left: 10px;">
                    Te has postulado
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div style="display: flex; margin-top:8px;">
                <div style="background-color: #6FCF97;border-radius: 32px;display: flex;color: #fff;padding: 4px 16px; align-items: center;">
                  <div>
                    <q-icon name="check_circle" style="color: #fff; font-size: 1.4em;" />
                  </div>
                  <div style="font-size: 14px;font-weight: 400;margin-left: 10px;">
                    Te has postulado
                  </div>
                </div>
              </div>
            </template>

          </template>

        </template>

      </pageHeader>

      <div id="dash_vacs_contentPage" class="dash_vacs_contentPage">
        <div tag="div" id="dash_vacs_contents" class="dash_vacs_contents " v-if="zinitData===true">

          <div class="dash_vacs_container">

            <div class="vacante_detailZone">

              <!-- Encabezado del profile -->
              <div class="profile_DataCard">
                <div class="profilecol-data-res">
                  <div class="title">Jornada</div>
                  <div class="data_value">
                    <div>
                      {{ currentVacante._embedded.jornada.nombreCatalogo }}
                    </div>
                    <div class="data_valueAlt">{{currentVacante._embedded.jornada.categoria}}</div>
                  </div>
                </div>

                <div class=" profilecol-data-res" :style="(is_Mobile|| $q.screen.lt.sm) ? 'margin-top:16px;': ''">
                  <div class="title">Horario</div>
                  <div class="data_value">
                    {{ (currentVacante._embedded.horario.nombreCatalogo ==='') ? 'Sin datos' : currentVacante._embedded.horario.nombreCatalogo }}
                    <!-- {{ (desc_nivelTrabajo ==='') ? 'Sin datos' : desc_nivelTrabajo }} -->
                  </div>
                </div>

                <div class="profilecol-data-res" :style="(is_Mobile|| $q.screen.lt.sm) ? 'margin-top:16px;': ''">
                  <div class="title">Sueldo integrado bruto</div>
                  <div class="data_value">
                    $ {{ currentVacante.z_sueldoTotal }}
                  </div>
                  <div class="data_valueAlt">Sueldo mensual estimado</div>
                </div>
              </div>

              <!-- Actividades y funciones -->
              <div class="profile_descriptionZone">
                <div class="sectionTitle">Actividades y funciones</div>
                <div 
                  id="resumenDescription_contents" 
                  class="resumenDescription_contents" 
                  v-html="currentVacante.descripcion"
                  style="margin-top:16px;"
                ></div>
              </div>

              <div class="profile_descriptionZone">

                <div class="sectionTitle" style="padding-bottom:16px; border-bottom: 1px solid #F1F1F4;">Detalles del sueldo</div>
                <!-- Sueldo principal -->
                <div class="vacante_sueldosZone" style="padding-bottom: 100px;">

                  <div class="row" style="margin-top:16px; justify-content: center;">
                      <div class="col-12 col-xs-12 col-sm-12 text-center" 
                        :style="(is_Mobile|| $q.screen.lt.sm)? '' : 'max-width:75%;' "
                        style="padding-bottom: 8px;border-bottom: 1px solid #F1F1F4;"
                      >
                        <!-- 472px; -->
                        <div class="sueldo_main">$ {{ currentVacante.z_sueldoTotal }}</div>
                        <div class="sueldo_main_label">Sueldo integrado bruto</div>
                      </div>
                  </div>
                  <!-- Conceptos de pago  -->
                  <div class="row" style="margin-top:16px; justify-content: center;"
                    :style="(is_Mobile|| $q.screen.lt.sm) ? '' : 'max-width: 75%; margin: auto;'"
                  >
                    <div
                      v-for="(cpago, index) in currentVacante._embedded.compensacionesPago"
                      class="col col-xs-12 col-sm-12 col-md-3 text-center"
                      :key="'cpago_item_' + index + '_' + cpago.id"
                      style="margin-top:16px;"
                      :class="{
                        'text-center':(is_Mobile|| $q.screen.lt.sm)? true : false
                      }"
                    >
                      <div
                        style="font-weight: 500; font-size: 15px; color: #24222A; line-height: 18px;"
                      >{{cpago.nombreConcepto}}</div>
                      <div
                        style="font-weight: 400; font-size: 15px; color: #24222A; line-height: 22px; margin-top:8px:"
                      >$ {{ mynumeral(cpago.costoMensual).format('0,0.00') }} pesos</div>
                    </div>

                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>
      </div>

    </template>

    <template v-else-if="hasVacanteData===false && zinitData===true">

      <div class="vacs_nodata_zone">
        <div class="title spacerRowTop48">
          ¡La vacante solicitada no existe <template v-if="(is_Mobile || $q.screen.lt.sm)"><br> </template> o ya no esta disponible!
        </div>
        <div class="text_subExplainer spacerRowTop4">
          Para poder visualizar las vacantes laborales disponibles <template v-if="(is_Mobile || $q.screen.lt.sm)"><br> </template> 
          ve al menú de vacantes <span class="action_item vacantes_actionText" @click.stop="goVacantesPage">dando clic aquí</span>
        </div>
        <div class="nodataImage spacerRowTop48" style="justify-content: center;display: flex;">
          <cxBackground :bgType="'planes_nosearch'" :size="currentSizeNodata" />
        </div>
      </div>

    </template>

    <confirmaTipoPerfilDialog
      ref="confirmaTprofile"
      :modalTitle="'¡Espera!'"
      @doCloseDialog="modal_postulaConfirm_close"
    >
      <template v-slot:contentArea="slotProps">
        <div style="font-family: 'Roboto'; font-style: normal; font-size: 15px; line-height: 24px;"
          :class="{testProcess: slotProps.process ? true:false }"
        >
          <div style="font-weight: 700; color: #474554;" >
              {{postularErrorText}}
          </div>
          <div style="font-weight: 400; color: #474554;">
            Ya no es necesario que te postules, pronto se pondrán en contacto contigo.
          </div>
        </div>
      </template>
      <template v-slot:buttonsArea="slotProps">
        <div :class="{testProcess: slotProps.process ? true:false }">
          <div>
            <q-btn 
              :label="'Entiendo'"
              unelevated 
              type="button" 
              class="buttonSave" 
              color="app_mainBtn" 
              :disabled="slotProps.process"
              @click.stop="modal_postulaConfirm_close"
            />
          </div>
        </div>
      </template>
    </confirmaTipoPerfilDialog>

  </div>
</template>

<script>
import { requestPack } from './../../../boot/axios' // APP code 

import pageHeader from 'components/layout/headerPage'

import logOut_Mixin from './../../../mixins/requestMix.js'
import myplatform_Mixin from './../../../mixins/platformMix.js'
import helperBase_Mixin from './../../../mixins/helperBase.js'
import helperCats_Mixin from './../../../mixins/helperCatsMix.js'

import numeral from 'numeral'

import canna_ConfDialog from 'components/modals/confirm_modal.vue'


export default {
  name:'vacantes_detail',
  mixins: [
    helperBase_Mixin,
    logOut_Mixin,
    myplatform_Mixin,
    helperCats_Mixin
  ],
  components:{
    cxIcon: require('components/appIcons.vue').default,
    cxBackground: require('components/appBackgrounds').default,
    pageHeader,
    confirmaTipoPerfilDialog: canna_ConfDialog
  },
  data () { 
    return {
      version: '1.0.1',
      debugEls: false,
      mynumeral: numeral, // Utilerias
      //-------------
      zinitData:false,
      zinitScreen: false,
      zloadingData: false,
      processingData: false,
      loading_Process: false, 
      currentSizeNodata:'300px',
      //------------
      postularErrorText: '', 
      vacantesData:[]
    }
  },
  computed: {
    currentVacante (){ 
      return this.$store.state.myapp.currentVacanteDetail
    },
    hasVacanteData (){ 
      return  (this.$store.state.myapp.currentVacanteDetail===null) ? false :true 
    },
    estatusx_USR () { 
      return this.$cannaEstatus.usuario 
    },
    current_profile (){
      return this.getProfileData
    },
    currStatusPersona (){ 
      return this.getProfileData.status
    },
    currProfileIdx () {
      var myIdCurrplan = this.$route.params.idx
      return myIdCurrplan
    }
  },
  watch: {

  },
  methods: {
    ...requestPack,
    testFn () { 
      this.$cannaDebug('-- vacantes detail -- test')
    },
    test () { 
      this.$cannaDebug('-- vacantes detail -- test')
    },
    showLoadx ( value, type ){ // type: 1 orbit -- 2 gears 
      var tpuse = (type===undefined || type===null || type==='') ? 2 : (type === 1 || type === 2) ? type : 2 
      this.$parent.showLoadr(tpuse , value )
    },
    goVacantesPage(){ 
      this.$cannaDebug('-- vacantes detail -- go Vacantes Page')
      var newRuta = '/dashboard/vacantes'
      setTimeout(() => {
        this.$router.push(newRuta)
        this.$store.commit('myapp/setMainState',{ currentVacanteDetail: null })
      }, 100)
    },
    //---------------
    modal_postulaConfirm_close () { 
      this.$cannaDebug('-- vacantes detail -- modal_postulaConfirm_close')
      if (this.$refs.confirmaTprofile ){
        this.$refs.confirmaTprofile.doHide()
      }
      this.postularErrorText = '' 
      this.goVacantesPage() 
    },
    //---------------
    async addJobProfilePersona (event){ 
      this.$cannaDebug('-- vacantes detail -- init Form Data')
      var test=11
      var profileIDx = this.currProfileIdx 
      var mcModel = this.getModelRequest('profile_Vacantes_Postular')
      var reqRes = { action: [] }
      var reqErr = { action: 0 }
      this.showLoadx(true, 1)
      var myFormConfig = this.createAxiosConfig ('post', mcModel, {
        useData: true, 
        data:{
          plan_perfil_laboral_id: profileIDx,
          postulado : 1
        }
      }, true, null)
      await this.doRequestByPromise(this.$apiV2, 'profDocs', reqRes.action, myFormConfig).then((resx) => {
        // console.log ('Action -- Carga de Docs -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      //-----------------------
      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        //REvisar el ERror para ver si ya Se preselecciono a la persona 
        this.showLoadx(false, 1)
        try { 
          if (reqRes.action[0].data.status === 422 && reqRes.action[0].data.data.message.indexOf('considerado para está vacante')>=0 ){
            if (this.$refs.confirmaTprofile ){
              //Uno de nuestros administradores ya te ha considerado para está vacante.
              this.postularErrorText = '' + reqRes.action[0].data.data.message 
              setTimeout(() => {
                this.$refs.confirmaTprofile.doShow()
              }, 250)
            }
          } else { 
            this.ex_handleErrorRequest (reqRes.action[0], reqErr.action)
          }
        } catch (errx) {
        } finally {
        }
        return false 
      }
      await this._waitRequestProc(1000)
      // -----------------------
      // Hasta aqui todo bien y recargamos el perfil Actual con el postulado..! 
      var respInitData = null 
      await this.initFormData().then( respx => {
        respInitData = respx 
      })
      await this._waitRequestProc(200)
      this.$forceUpdate() 
      this.showLoadx(false, 1)
      return true
    },
    async initFormData () { 
      //Cargamos el Registro en especifico de 
      this.$cannaDebug('-- vacantes detail -- init Form Data')
      var test=11
      var profileIDx = this.currProfileIdx 
      var mcModel = this.getModelRequest('profile_Vacantes')
      var reqRes = { action: [] }
      var reqErr = { action: 0 }
      var myFormConfig = this.createAxiosConfig ('get', mcModel, {delimiters:[profileIDx] , 'per-page': 'all', expand:'compensacionesPago,jornada,horario', sort:'-id' }, true, null)
      await this.doRequestByPromise(this.$apiV2, 'profDocs', reqRes.action, myFormConfig).then((resx) => {
        // console.log ('Action -- Carga de Docs -- GET ejecutado')
        var tmpresponseData = reqRes.action[reqRes.action.length - 1]
        if (tmpresponseData.type !== 'success') {
          reqErr.action += 1
        }
      })
      //-----------------------
      if (reqErr.action > 0) { // Si hay error de server, session o algo redireccionamos..!
        test = 55
        //this.ex_handleErrorRequest (reqRes.action[0], reqErr.action)
        return false
      }
      // -----------------------
      var mVacData = reqRes.action[0].data.data
      var item = mVacData
      var x_sueldoBruto = 0
      item._embedded.compensacionesPago.forEach( cpago => { 
        var costMens = parseFloat(cpago.costoMensual)
        cpago.z_totalMensual = '$ ' + this.mynumeral(costMens).format('0,0.00')
        x_sueldoBruto+= parseFloat(cpago.costoMensual)
      })
      item.z_sueldoTotal = this.mynumeral(x_sueldoBruto).format('0,0.00')
      var fechaStr = this.$moment(item.fecha_publicacion, 'YYYY-MM-DD HH:mm:ss').format('DD, MMM, YYYY').replace(/\./g,'')
      item.z_subtitulo = '' + item.ciudadNombre + ' | Publicada el <span style="text-transform: capitalize;">' + fechaStr + '</span>'
      item.z_subtituloMobil = '' + item.ciudadNombre + ' <br> Publicada el <span style="text-transform: capitalize;">' + fechaStr + '</span>'
      
      var myObject  = JSON.parse(JSON.stringify(item))
      this.$store.commit('myapp/setMainState',{ currentVacanteDetail: myObject })
      await this._waitRequestProc(500)
      return true
    }
  },
  beforeCreate () {
    //this.$cannaDebug('-- vacantes detail -- beforeCreate')
  },
  created () {
    this.$cannaDebug('-- vacantes detail -- created')
    this.zloadingData = true 
    this.zinitData = false
    this.zinitScreen = false
    this.postularErrorText = ''
  },
  beforeMount () {
    // this.$cannaDebug('-- vacantes detail --  beforeMount')
  },
  async mounted () {
    this.$cannaDebug('-- vacantes detail -- mounted')
    this.zloadingData = true 

    if (this.hasVacanteData===false){ 
      this.showLoadx(true, 1)
      var respInitData = null 
      await this.initFormData().then( respx => {
        respInitData = respx 
      })
    }
    await this._waitRequestProc(200)
    this.zinitData = true 
    this.zloadingData = false
    this.showLoadx(false, 1)
    setTimeout(() => {
      this.zinitScreen = true
    }, 500)
    return true 
  },
  beforeUpdate () {
    // this.$cannaDebug('-- vacantes detail -- beforeUpdate')
  },
  updated () {
    // this.$cannaDebug('-- vacantes detail -- updated')
  },
  async activated () {
    this.$cannaDebug('-- vacantes detail -- activated')
    this.postularErrorText = ''
    if (this.zinitScreen = true){
      this.zloadingData = true
      this.showLoadx(true, 1)
      if (this.hasVacanteData===false ){ 
        var respInitData = null 
        await this.initFormData().then( respx => {
          respInitData = respx 
        })
        await this._waitRequestProc(1000)
      }
      this.zinitData = true
    }
    this.showLoadx(false, 1)
    this.zloadingData = false
  },
  deactivated () {
    // this.$cannaDebug('-- vacantes detail -- deactivated')
    this.postularErrorText = ''
    this.zinitData = false
  },
  beforeDestroy () {
    // this.$cannaDebug('-- vacantes detail -- beforeDestroy')
  },
  destroyed () {
    // this.$cannaDebug('-- vacantes detail -- destroyed')
  }
}
</script>

